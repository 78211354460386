<template>
    <div class="ranking_detail">
        <div class="mian">
            <div class="inner_nav clearfix">
                <div class="fl inner_nav_l">当前位置：<span><router-link to="/ranking">每日一字</router-link></span> > <router-link to="/ranking">每日上榜</router-link>  > {{info.title}}</div>
            </div>
        </div>
        <div class="mian">
            <div class="ranking_detail_info">
                <div class="ranking_detail_info_top clearfix">
                    <div class="fl detail_info_top_l">
                        <div class="detail_info_top_l_name">{{info.title}}</div>
                        <div class="detail_info_top_l_dec">上传时间：{{info.time}}  丨  作品已申请版权登记保护</div>
                    </div>
                    <div class="fr detail_info_top_r">
                        <div class="fl">分享到：</div>
                        <img class="fl" @click="$share.toQQ(info.title)" src="../assets/images/detailqq.png" alt="">
                        <img class="fl" @click="$share.toWeibo(info.title)" src="../assets/images/detailweibo.png" alt="">
                        <div class="fl wxShare">
                            <img src="../assets/images/detailweixin.png" alt="">
                            <div class="wxShare_box">
                                <div>使用微信 扫一扫</div>
                                <div id="qrcode" ref="qrcode"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ranking_detail_info_img" v-html="info.content" @click="showImg($event)">
                </div>
            </div>
        </div>
         <!-- 最新上榜 -->
        <div class="mian">
            <div class="public_title">
                <div class="public_title_l public_title_ls">
                    <div class="public_title_name">最新上榜</div>
                </div>
            </div>
            <div class="ranking_ul clearfix" v-if="list.length>0">
                <!-- wow animate__animated animate__fadeInUp -->
                <div class="fl daily_li " :style="{animationDelay:0.1*index+'s'}" v-for="(item,index) in list" :key="index" @click="toDetail(item.id)">
                    <div class="ranking_li_box">
                        <img v-lazy="item.thumb" alt="">
                    </div>
                    <div class="ranking_li_name">{{item.title}}</div>
                </div>
            </div>
            <el-empty v-else description="暂无数据"></el-empty>
        </div>
        <!-- 侧边导航 -->
        <sidebar ref="downtotal"></sidebar>

        <div class="imgDolg" v-show="imgPreview.show" @click.stop="imgPreview.show = false">      
            <i class="el-icon-close" id="imgDolgClose" @click.stop="imgPreview.show = false"></i>      
            <img @click.stop="imgPreview.show = true" :src="imgPreview.img" />    
        </div>
    </div>
</template>

<script>
const wx = require('weixin-js-sdk')
import QRCode from 'qrcodejs2'
// import { WOW } from 'wowjs'
import sidebar from '../components/sidebar.vue'
export default {
    name: "rankingDetail",
    components:{
        sidebar
    },
    data(){
        return{
            info:{},//详情

            list:[],//最新上榜

            imgPreview:{  img:"",  show:false}
        }
    },
    mounted(){
        this.$parent.routerIndex = 6;
        this.$parent.showIndex = 1;
        // new WOW().init();
        if(this.$route.query.id){
            this.initFun(this.$route.query.id)
        }
    },
    methods:{
        initFun(id){
            var that = this;
            var params ={
                id:id,
            }
            this.$api.POST(this.$face.onlistInfo,params,function(res){
                that.info = res.data;
                that.info.time = that.timeFun(that.info.create_time)
                that.list = res.data.news;
                that.$nextTick(() => {
                    let qrcode = new QRCode('qrcode',{
                        width: 100, // 设置宽度，单位像素
                        height: 100, // 设置高度，单位像素
                        text: 'https://m.zitisheji.com/#/pages/mycenter/uni_dailydetail?id='+id // 设置二维码内容或跳转地址
                    })
                })
            })
        },
        //时间处理
        timeFun(el){
            var arry = el.substring(0,10).split('-');
            return arry.join('/')
        },

        // 查看大图
        showImg(e){
            if(e.target.tagName == 'IMG'){
               this.imgPreview.img = e.target.src 
               this.imgPreview.show = true
            }
        },

        // 查看详情
        toDetail(id){
            //this.$router.push({name:'refresh',query:{id:id}})
            let routeUrl = this.$router.resolve({
				name: "rankingDetail",
				query: {
					id: id
				}
			});
			window.open(routeUrl.href, '_blank');
        },
    },
    watch:{
        
    }
};
</script>

<style scoped>
.ranking_detail{
    background-color: #f6f6f6;
}

.inner_nav {
    padding: 27px 0 17px;
}
.inner_nav_l {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #999999;
}


.ranking_detail_info{
    width: 100%;
    background: #FFF;
    border-radius: 10px;
    padding: 0 31px 0 24px;
    box-sizing: border-box;
    min-height: 600px;
    margin-bottom: 59px;
}

.ranking_detail_info_top{
    border-bottom: 1px solid #eeeeee;
}
.detail_info_top_l{
    padding: 43px 0 26px;
}
.detail_info_top_l_name{
    font-size: 24px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 24px;
	letter-spacing: 0px;
	color: #333333;
    margin-bottom: 18px;
}
.detail_info_top_l_dec{
    font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 12px;
	letter-spacing: 0px;
	color: #999999;
}
.detail_info_top_r{
    padding-top: 67px;
    font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 22px;
	letter-spacing: 0px;
	color: #999999;
    
}
.detail_info_top_r img{
    margin: 0 6px;
}
.detail_info_top_r img:last-child{
    margin-right: 0;
}

.ranking_detail_info_img{
    padding: 33px 0 50px;
}
.ranking_detail_info_img >>> img{
    max-width: 1000px;
    margin: 0 auto 14px;
}


.ranking_ul{
    margin: 24px -13.33px 26px;
}
.daily_li{
    width: 330px;
    height: 263px;
    border-radius: 10px;
    margin:0 13.33px 30px;
    padding: 14px 15px 0;
    box-sizing: border-box;
    background-color: #FFF;
}
.ranking_li_box{
    width: 100%;
    height: 198px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
.ranking_li_box img{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}
.ranking_li_name{
    font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 51px;
	letter-spacing: 0px;
	color: #333333;
}


.sidebar_r{
    right: 2%;
    left: auto;
}


.imgDolg {  
    width: 100vw;  
    height: 100vh;  
    position: fixed;  
    z-index: 9999;  
    background-color: rgba(140, 134, 134, 0.6);  
    top: 0;  
    left: 0;  
    display: flex;  
    align-items: center;  
    justify-content: center;  
}
#imgDolgClose {    
    position: fixed;    
    top: 35px;    
    cursor: pointer;    
    right: 4%;    
    font-size: 50px;    
    color: white;  
}  
.imgDolg img{    
    height: 80%;
    margin-right: 12px;  
}
</style>